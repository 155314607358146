html {
  scroll-behavior: smooth;
}

#product-faq-title,
#product-order-title,
#product-storage-title {
  scroll-margin-block-start: 140px;
  scroll-margin-block-end: 110pxx;
}

.faqs-container {
  border-left: 1px solid #c7c7c7;
  padding-left: 2rem;
}

.ui.styled.accordion .title {
  border-top: none !important;
}
.ui.styled.accordion,
.ui.styled.accordion .accordion {
  box-shadow: none !important;
  margin-bottom: 1rem;
}
.ui.styled.accordion .title {
  color: black;
  font-weight: 500 !important;
  padding-left: 0.3rem;
  padding-right: 0;
}
.ui.styled.accordion .content {
  margin: 0;
  padding: 0.5em 0.3rem 1.5em;
}

@media screen and (max-width: 767px) {
  .ui.styled.accordion {
    width: 100% !important;
  }
}
