.ui.image.footer-pinch-logo {
  margin: 0 !important;
}
img.ui.image {
  display: inline-block !important;
}
.ui.small.image.footer-pinch-logo {
  width: 100px !important;
}
.footer-text {
  color: white !important;
  text-align: left !important;
}

.ui.input.email-input-footer {
  font-size: 0.9rem;
  width: 320px;
  color: white !important;
  border-bottom: 1px solid rgba(168, 168, 168, 0.626);
  padding-bottom: 5px;
  color: var(--primary-cream-20);
}

.ui.form > .email-input-footer > Input[type="email"] {
  color: white !important;
}
.ui.form > .email-input-footer > Input[type="email"]:focus {
  border-color: transparent !important;
}
.ui.form > .email-input-footer > Input[type="email"]::selection {
  background-color: white !important;
}

.ui.form input[type="text"]::selection {
  background-color: var(--primary-cream) !important;
}
.footer-link-page {
  font-weight: 300;
}
.footer {
  gap: 50px !important;
}

.footer-heading {
  font-size: 1.2rem;
}
.footer-copyright {
  font-family: var(--font-inter);
  font-size: 0.9rem;
  font-weight: 300;
}

@media only screen and (max-width: 1024px) {
  .footer-heading {
    font-size: 1rem;
  }
  .footer-copyright {
    font-size: 0.8rem;
  }
  .ui.small.image.footer-pinch-logo {
    width: 80px !important;
  }
}
