html,
body {
  overflow-x: hidden;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.ui.primary-button {
  background-color: #111c4e;
  color: white;
  font-size: 0.6em !important;
}

/* .Toastify__toast-theme--light {
  background-color: black !important;
  color: var(--primary-cream) !important;
} */

/* .Toastify__progress-bar {
  background: var(--primary-violet-20) !important;
} */

.Toastify__progress-bar {
  color: purple;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.styles-module_chatHeader__gaeOA .styles-module_avatar__afcc4 img {
  width: 40px !important;
  height: 40px !important;
  background: #b780d0;
  padding: 0 0.1rem;
}
.styles-module_chatHeader__gaeOA .styles-module_avatar__afcc4:after {
  right: 15px !important;
}
.styles-module_chatHeader__gaeOA .styles-module_status__-ln3V {
  padding: 0 !important;
}
.styles-module_chatHeader__gaeOA .styles-module_avatar__afcc4 {
  text-align: -webkit-center !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
