.featured-blog-title {
  font-family: var(--font-family-display);
  letter-spacing: -0.025em;
  color: black;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: left;
}
.featured-blog-para {
  font-family: var(--font-inter);
  letter-spacing: 0em;
  color: rgb(110, 110, 110);
  font-weight: 400;
  line-height: 1.3rem;
  font-size: 1rem;
  text-align: left;
}

/* all blogs */
.all-blogs-title {
  font-family: var(--font-family-display);
  letter-spacing: -0.025em;
  color: black;
  font-weight: 500;
  font-size: 1.2rem;
  text-align: left;
}
.all-blogs-para {
  font-family: var(--font-inter);
  letter-spacing: 0em;
  color: rgb(110, 110, 110);
  font-weight: 400;
  line-height: 1.3rem;
  font-size: 0.9rem;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .featured-blog-title,
  .all-blogs-title {
    font-size: 1rem;
    line-height: 1.3rem;
  }
  .featured-blog-para,
  .all-blogs-para {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }
}
