.blog-title-60 {
  font-family: var(--font-family-display);
  letter-spacing: -0.025em;
  color: black;
  font-weight: 400;
  font-size: 2.5rem;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .blog-title-60 {
    font-size: 2rem;
    line-height: 2.2rem;
  }
}
