.ui.breadcrumb .active.section {
  font-size: 0.9rem;
  font-weight: 500;
  color: #4b4b4b !important;
}
.ui.breadcrumb a.section {
  font-size: 0.9rem;
  font-weight: 400;
  color: #4b4b4b;
}
