.cake-text-wrapper {
  width: 100%;
  text-align: center;
}

.gifts-btn {
  margin-left: 28%;
}

.homepage-divider {
  background: var(--primary-violet);
  margin: 0 100px !important;
}
.ui.divider:not(.vertical):not(.horizontal) {
  border-top: 0.5px solid rgba(34, 36, 38, 0.15);
  border-bottom: 0;
}

.d-concierge-section {
  padding: 1px;
  height: 45rem;
}
.d-concierge-section-col-1 {
  margin-right: 5rem;
}

.d-concierge-section-col-2 {
  width: 40rem !important;
  background: var(--primary-cream);
  margin-top: 10%;
  padding: 3rem !important;
}

.homepage-bg-long {
  background: var(--primary-violet-20);
  height: fit-content;
}

.d-working-tough {
  padding: 1px;
  height: fit-content;
  background: var(--primary-lemongrass-20);
}

.d-working-tough-col {
  height: fit-content;
}

.working-tough-grip-wrap {
  max-width: 1400px;
  margin: auto !important;
  /* margin: 5rem; */
  padding: 0 8rem 5rem 8rem !important;
}

.navbar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}
.concierge-banner {
  height: 500px;
}
.big-concierge-banner {
  height: 300px;
}

.m-row-working-tough {
  padding: 4rem 15rem !important;
}
@media screen and (max-width: 767px) {
  .m-grid-working-tough {
    margin-top: 0rem !important;
  }
  .row {
    padding: 0 !important;
  }
  .m-row-working-tough {
    padding: 4rem 2rem !important;
  }
  .concierge-header {
    margin: 0px 60px !important;
  }
  .section-wrapper {
    background: var(--primary-cream);
    padding: 12%;
  }
  .cake-text-wrapper {
    padding: 1.2rem 2rem;
  }
  .homepage-divider {
    margin: 0 35px !important;
  }
  .homepage-bg-long {
    background: var(--primary-violet-20);
    height: fit-content;
  }
  .working-tough-wrapper {
    padding: 3rem 0rem;
  }
  .working-tough-header {
    padding: 2rem 2rem 0 !important;
  }

  .d-working-tough {
    padding: 1px;
    height: fit-content;
    background: var(--primary-lemongrass-20);
  }
}
