
.left-arrow-icon {
  font-size: 25px;
}
.ui.button.customised-btn {
  letter-spacing: 0.4px;
  font-family: var(--font-inter);
  background-color: var(--primary-violet);
  color: black;
  border-radius: 0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  padding: 0.8rem 1.3rem;
  font-size: 1rem;
  font-weight: 600;
}
.ui.button.customised-btn:hover {
  background-color: var(--primary-cream-20);
}
.ui.small.image.giftbox {
  height: 29px;
  margin-left: 10px;
  display: inline;
  width: 30px;
}
.right-arrow-icon {
  font-size: 25px;
}

.sidebar-halal-logo {
  width: 50px;
}
.sidebar-animate-right {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}
.sidebar-animate-left {
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

.btn-width-100 {
  width: 100%;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateX(-100%);
  }
}
@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}
