@media only screen and (max-width: 1024px) {
  .menu-icons {
    font-size: 1.5rem;
  }
  .navbar-menu {
    padding: 0 0.5rem;
  }
  .ui.small.image.burger-menu {
    width: 22px;
  }
  .ui.secondary.inverted.pointing.menu .item.mb-item {
    padding: 0.5rem;
    align-self: auto;
    cursor: pointer;
  }
  .ui.image.pinch-logo {
    padding-left: 0;
    margin: 0.3rem 0.3rem;
    width: 80px;
    height: 36px;
  }
  .ui.menu:after {
    content: none;
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
  }
  .cart-number {
    position: relative;
    width: 18px;
    height: 18px;
    right: 11px;
    font-size: 1rem;
    bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: var(--primary-grapefruit);
  }
}
