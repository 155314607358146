@media only screen and (max-width: 1024px) {
  .last-second-image-mr {
    margin-right: 0rem;
  }
  * .ui.large.image {
    width: 250px;
    height: 200px;
    object-fit: cover;
  }
  .image-column-width {
    width: 280px !important;
    height: 300px !important;
  }
}

@media only screen and (min-width: 1024px) {
  /*! Navbar Desktop */
  .navbar-menu {
    padding: 0 3rem;
  }
  .ui.secondary.inverted.pointing.menu .item {
    padding-left: 0;
    padding-right: 12px;
    color: rgba(255, 255, 255) !important;
  }
  .ui.secondary.pointing.menu .item.navbar-menu-item {
    align-self: auto;
    margin-left: 5px;
    padding-left: 2px;
    padding-right: 2px;
    margin-right: 12px;
    margin-left: 12px;
    font-size: 0.9rem;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
  }

  .ui.secondary.pointing.menu .active.item {
    font-weight: 500;
  }

  .ui.secondary.inverted.pointing.menu .active.item {
    border-color: var(--primary-violet);
    border-bottom-width: 5px;
    padding-bottom: 8px;
  }
  .ui.image.pinch-logo {
    padding-left: 0;
    margin: 0.3rem 1.5rem;
    width: 80px;
    height: 36px;
    margin-left: 12px;
  }
  .ui.menu:not(.vertical) .item > .button.customised-btn {
    letter-spacing: 0.4px;
    font-family: var(--font-inter);
    background-color: var(--primary-violet);
    color: black;
    border-radius: 0;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 0.5rem 1.1rem;
    font-size: 0.9rem;
    font-weight: 600;
  }
  .ui.menu:not(.vertical) .item > .button.customised-btn:hover {
    background-color: var(--primary-cream-20);
    color: black;
  }
  .ui.small.image.giftbox {
    height: 29px;
    margin-left: 10px;
    display: inline;
    width: 30px;
  }
  .ui.secondary.pointing.menu .active.item:hover {
    cursor: pointer;
    background: transparent;
  }

  .cart-number {
    position: relative;
    width: 21px;
    height: 21px;
    right: 11px;
    bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: var(--primary-grapefruit);
  }

  /* header icons */
  .menu-icons {
    cursor: pointer;
    font-size: 25px;
  }

  /* !Segment css */
  .ui.segment {
    margin-top: 0;
  }

  /* Navbar Modal List */
  .ui.list.link-style {
    font-size: 0.9rem;
  }

  .ui.link.list.list .active.font-light {
    font-weight: bold;
    letter-spacing: 0.3px;
  }

  .ui.link.list.list .active.item {
    color: black;
  }

  .ui.link.list.list .active {
    margin-bottom: 1rem;
  }

  .ui.large.image {
    width: 400px;
    height: 290px;
    object-fit: cover;
  }
  .navbar-link {
    text-decoration: none;
  }

  .navbar-link:hover {
    text-decoration: underline 0.15em #1e1e1c;
    text-underline-offset: 0.1em;
  }

  .navbar-image-text-link {
    cursor: pointer;
    position: relative;
    padding-bottom: 5px;
    text-decoration: none;
  }
  .navbar-image-text-link:hover {
    color: black;
  }

  .navbar-image-text-link:hover:before {
    -webkit-animation: scale(1) translate3d(100%, 0, 0) 0.6s ease-in forwards;
    animation: scale(1) translate3d(100%, 0, 0) 0.6s ease-in forwards;
  }

  .navbar-image-text-link:before {
    -webkit-transform: scale(0.2, 1) translateX(-100%);
    transform: scaleX(0.2) translate(-100%);
    -webkit-transform-origin: left;
    transform-origin: left;
    width: 100%;
    height: 2px;
    background: #fff;
    z-index: 10;
  }

  .navbar-image-text-link:after {
    content: "";
    position: absolute;
    bottom: 0;
    transform: translate(-100%);
    width: 100%;
    height: 2px;
    background: #1e1e1c;
    animation: 0.2s 0.1s linear forwards;
    -webkit-animation: 0.2s 0.1s linear forwards;
    -webkit-animation: translate3d(0, 0, 0) 0.2s 0.1s linear forwards;
  }

  .navbar-image-text-link:hover {
    cursor: pointer;
  }

  .content-column-width {
    width: 160px !important;

    padding-right: 0 !important;
  }
  .image-column-width {
    width: 400px !important;
  }
  .last-second-image-mr {
    margin-right: 2.5rem;
  }

  /* search Icon */
  .search-bar-text > input {
    letter-spacing: 0.2px !important;
  }
  .ui.menu:not(.vertical) .item > .input {
    font-size: 1rem;
    width: 200px;
    color: white;
    border-bottom: 1px solid white;
    padding-bottom: 5px;
  }
  .ui.menu .item > .input input {
    color: white;
  }
  .icon:hover {
    cursor: pointer !important;
  }
  .ui.menu .item > .input input::selection {
    background: var(--primary-cream-20);
  }
}
