.ui.accordion .title .dropdown.icon:before {
  font-size: 1.2rem;
}
.ui.large.image.mobile-navbar-images {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.sidebar-animate-right {
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    -webkit-transform: translateX(-100%);
  }
}
