@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Inter", sans-serif;
  font-family: "Playfair Display", serif;
  background-color: #fffbf9 !important;
}

:root {
  --primary-violet: #b780d0;
  --primary-orange: #ff7f2f;
  --primary-peach: #38160c;
  --primary-lemongrass: #dbe241;
  --primary-freshmint: #65c9bb;
  --primary-grapefruit: #ff5a5a;
  --primary-cream: #f1e8e2;
  --primary-violet-20: #f4e5f7;
  --primary-tangerine-cream: #ffa489;
  --primary-orange-20: #ffe7d5;
  --primary-peach-20: #ffede7;
  --primary-lemongrass-20: #fff9dd;
  --primary-freshmint-20: #e5f6f1;
  --primary-grapefruit-20: #ffe1dd;
  --primary-cream-20: #fffbf9;
  --black-color: #000000;
  --white-color: #ffffff;
  --font-family-display: "Playfair Display", "serif";
  --font-inter: "Inter", "sans-serif";
}

/* !COMMON FONT STYLE FOR WHOLE SITE - DESKTOP */
body {
  background-color: var(--primary-cream-20);
}
.family-inter {
  font-family: var(--font-inter);
}
.family-headline {
  font-family: var(--font-family-display);
}

/* Desktop Headline */
.headline-30 {
  font-family: var(--font-family-display);
  font-size: 1.3rem;
}
.headline-40 {
  font-family: var(--font-family-display);
  font-size: 1.7rem;
  letter-spacing: -0.025em;
  color: black;
}
.headline {
  font-family: var(--font-family-display);
  letter-spacing: -0.025em;
  color: black;
  font-weight: 400;
}
.desktop-headline-60 {
  font-family: var(--font-family-display);
  font-size: 2.5rem;
  letter-spacing: -0.025em;
  color: black;
  font-weight: 400;
}
/* New FONTS css  */

/* Mobile Headline */
.mobile-headline-40 {
  line-height: normal;
  font-family: var(--font-family-display);
  font-size: 2.1rem;
  letter-spacing: -0.025em;
  color: black;
}
.mobile-headline-20 {
  font-family: var(--font-family-display);
  font-size: 1.4rem;
}
.mobile-para-15 {
  font-family: var(--font-inter);
  font-size: 0.9rem;
  font-weight: 400;
}
.desktop-inter-20-bold {
  font-family: var(--font-inter);
  font-size: 0.9rem;
  letter-spacing: 0.2px;
  font-weight: 600;
}
.mobile-inter-10 {
  font-family: var(--font-inter);
  font-size: 0.8rem;
  letter-spacing: 0.1px;
  font-weight: 600;
}
.mobile-inter-15 {
  font-family: var(--font-inter);
  font-size: 1rem;
}
.mobile-inter-25 {
  font-family: var(--font-inter);
  font-size: 1rem;
}

/* Mobile & Navbar Header common CSS */
.navbar-modal {
  position: absolute;
  left: 0;
  top: 1;
}
.ui.inverted.menu {
  background-color: black !important;
}

.ui.inverted.segment.navbar-segment {
  margin: 0;
  border-radius: 0;
  padding: 0;
}
.header-stick {
  position: sticky;
  top: 0;
  z-index: 100;
}
.sidebar {
  height: min-content;
  width: 100%;
  z-index: 200;
  left: 0;
  top: 0;
}

/* !COMMON FONT STYLE FOR WHOLE SITE - DESKTOP  */
.text-18 {
  font-size: 0.9rem !important;
  font-family: var(--font-inter);
  letter-spacing: 0em;
  color: black;
  font-weight: 400;
}
.title-50 {
  font-family: var(--font-family-display);
  letter-spacing: -0.025em;
  color: black;
  font-weight: 400;
  font-size: 2.2rem;
}
.title-60 {
  font-family: var(--font-family-display);
  letter-spacing: -0.025em;
  color: black;
  font-weight: 400;
  font-size: 2.5rem;
  text-align: center;
}
.title-40 {
  font-family: var(--font-family-display);
  letter-spacing: -0.025em;
  color: black;
  font-weight: 400;
  font-size: 1.8rem;
  text-align: center;
}

.para-25 {
  font-family: var(--font-inter);
  letter-spacing: 0em;
  color: black;
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
}
.sm-text-18 {
  font-size: 0.9rem !important;
  font-family: var(--font-inter);
  letter-spacing: 0em;
  color: black;
  font-weight: 400;
}
.error-text,
.success-text,
.info-text {
  font-size: 0.8rem !important;
  font-family: var(--font-inter);
  letter-spacing: 0em;
  font-weight: 400;
}
.error-text {
  color: var(--primary-grapefruit) !important;
}
.success-text {
  color: black;
}
.info-text {
  color: var(--primary-orange) !important;
}
.order-placed-text {
  font-size: 1rem !important;
  font-family: var(--font-inter);
  letter-spacing: 0em;
  font-weight: 500;
  color: var(--primary-grapefruit);
}

.ui.form input[type="email"],
.ui.form input[type="password"],
.ui.form input[type="text"],
.ui.form .text-input[type="text"],
.ui.form input[type="number"],
.ui.form input[type="checkbox"],
.ui.form .text-input[type="text"],
.ui.form textarea,
.default-add {
  border-radius: 0 !important;
  padding: 1.2em 1em;
  border-color: #c7c7c7;
  font-family: var(--font-inter);
  font-size: 0.9rem !important;
  letter-spacing: 0em;
  color: black !important;
  font-weight: 400;
  background: transparent !important;
}
.ui.form input[type="checkbox"] {
  cursor: pointer;
}

.ui.form input[type="email"]::placeholder,
.ui.form input[type="password"]::placeholder,
.ui.form input[type="text"]::placeholder,
.ui.form input[type="number"]::placeholder,
.ui.form textarea::placeholder {
  color: rgb(101, 101, 101);
}

.ui.form input[type="email"]:focus,
.ui.form input[type="password"]:focus,
.ui.form input[type="text"]:focus,
.ui.form input[type="number"]:focus,
.ui.form textarea:focus {
  border-color: var(--primary-violet) !important;
}
.default-add {
  padding: 0 !important;
}
input[type="checkbox"] {
  border-color: green;
}
.form-input:focus {
  border-color: var(--primary-violet) !important;
  border-radius: 0 !important;
}
.tostify {
  background-color: black;
}

.close-icon {
  font-size: 25px;
}
.summary-product-name {
  font-family: var(--font-family-display);
  letter-spacing: 0em;
  color: black;
  font-weight: 400;
  font-size: 1rem;
  text-align: left;
}

/* underline style */
.underline-style-under {
  text-underline-position: under;
}

.bin {
  color: #ff5a5a;
  width: 14px !important;
  cursor: pointer;
}
.text-area:focus-visible {
  outline: none;
  background: transparent;
}
.text-area {
  background-color: transparent;
  height: 80px;
}
.fond-text-area {
  background-color: transparent;
  height: 50px;
}
.fond-text-area:focus-visible {
  outline: none;
  background: transparent;
}
.border-grey {
  border: 1px solid rgb(120, 119, 119);
}

.border-violet {
  border: 1px solid var(--primary-violet);
}
.border-black {
  border: 1px solid black;
}
.coupon-input {
  border-radius: 0 !important;
  background-color: transparent !important;
  padding: 10px 14px;
  border: 1px solid #c7c7c7;
}

/* tracking details container */
.user-panel {
  font-family: var(--font-inter) !important;
}

.tracking-container-border {
  border: 1px solid var(--primary-violet) !important;
  padding: 1rem;
  margin-bottom: 3rem !important;
  margin-top: 2rem;
}
/* Category name font style  */

.category-name {
  text-transform: uppercase;
  font-size: 0.8rem !important;
  font-family: var(--font-inter);
  letter-spacing: 0em;
  color: #727272;
  font-weight: 400;
}

/* !COMMON FONT STYLE FOR WHOLE SITE - MOBILE */
@media only screen and (max-width: 1024px) {
  .title-60 {
    font-size: 2rem;
  }
  .para-25 {
    font-size: 0.9rem;
  }
  .title-40 {
    font-size: 1.4rem;
  }
  .title-50 {
    font-size: 1.8rem;
  }
  .sm-text-18 {
    font-size: 0.8rem !important;
  }
  .category-name {
    font-size: 0.7rem !important;
  }
}
