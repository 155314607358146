.sm-text-18 {
  font-size: 0.9rem !important;
  font-family: var(--font-inter);
  letter-spacing: 0em;
  color: black;
  font-weight: 400;
}
@media only screen and (max-width: 1024px) {
  .sm-text-18 {
    font-size: 0.8rem !important;
  }
}
